import * as React from 'react'

import './index.scss'

import UE from './assets/ue.svg'
import GR from './assets/gr.svg'
import IS from './assets/is.svg'

export default function Header() {
  return (
    <div className='Header'>
      <div className='Icons'>
        <img src={UE} alt='' />
        <img src={GR} alt='' />
        <img src={IS} alt='' />
      </div>
      <p>
        Pentru informaţii detaliate despre celelalte programe cofinanțate de Uniunea Europeană, vă invităm să vizitaţi
        {' '}
        <span>
          <a target='_blank' rel='noopener noreferrer' href='https://www.fonduri-ue.ro'>www.fonduri-ue.ro</a>
        </span>
      </p>
    </div>
  )
}
