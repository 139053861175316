import * as React from 'react'

import './index.scss'

export interface ISectionProps {
  id?: string
  title: string
}

export default function Section (props: React.PropsWithChildren<ISectionProps>) {
  return (
    <div id={props.id} className='Section'>
      <h1>{props.title}</h1>
      {props.children}
    </div>
  )
}
