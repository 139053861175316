import * as React from 'react'

import './index.scss'

export default function Vision() {
  return (
      <div className='Vision'>
        <div className='Column'>
          <p>Odată cu creșterea exponențiala a produselor și numărului de dispozitive sub incidenta IoT, volumele de informații au explodat, ridicând simultan nivelul de importanta al securității acestora. Un aspect cheie al adoptării produselor IoT este dat de încrederea utilizatorilor în tehnologie, aspect pe care CODA Intelligence își propune sa îl îmbunătățească, crescând astfel adresabilitatea întregii tehnologii. Platforma SABLO este remediu pentru aceasta categorie de sceptici prin oferirea unei platforme deschise, prin care clienții să își controleze expunerea la risc, înțelegând vulnerabilitățile pe care le implica produsele IoT. Platforma SABLO produce un impact pozitiv vizibil atât la nivel business, cât și către piața de consumatori, nevoia de control a securității fiind din ce în ce mai presanta pentru adoptarea edge-urilor tehnologice.</p>
          <p>Proiectul SABLO reprezintă totodată și o aliniere cu tendințele și reglementările la nivelul Uniunii Europene, reprezentând un nivel suplimentar de securitate în privința datelor cu caracter personal, cât și a identificării vulnerabilităților spațiului digital personal.</p>
        </div>
        <div className='Column'>
          <p>Totodată, platforma SABLO oferă furnizorilor existenți de tehnologie posibilitatea de a le permite clienților proprii să își asigure un management al securității activelor personale, posibilitate a cărei dezvoltare internă nu ar fi prioritară în detrimentul dezvoltărilor ce țin de specificul de business al fiecărui furnizor de tehnologie în parte. </p>
          <p>Viziunea sub care a fost dezvoltată soluția SABLO a avut în vedere poziționarea acesteia ca o soluție de tip „white label” pentru a facilita adoptarea tehnologiei de către furnizorii existenți și de a o expune în fața clienților finali. Această modalitate de comercializare și distribuire este frecvent întâlnită în toate industriile, inclusiv cele care sunt în sfera tehnologiei informației, și reprezintă o posibilitate de a oferi susținere în dezvoltare și vizibilitate în rândul clienților într-un timp mult mai scurt.</p>
        </div>
      </div>
  )
}
