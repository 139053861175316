import * as React from 'react'

import Box from '../Box'
import Capture from '../Capture'

import Overview from './assets/overview.png'
import ScanSurface from './assets/scanSurface.png'
import Vulns from './assets/vulns.png'
import Applications from './assets/applications.png'
import Alerts from './assets/alerts.png'

import './index.scss'

export default function Functionalities() {
  return (
    <div className='Functionalities'>
      <div className='FunctionalitiesList'>
        <Box
          title='Scanare IoT fără agent'
          text='Reprezintă metoda de obținere a datelor cu privire la caracteristicile infrastructurii informatice, metoda care nu presupune instalarea unui program permanent pe activele clientului. Spre deosebire de celelalte metode, scanarea fără agent din exteriorul rețelei locale presupune cel mai redus consum de resurse din partea infrastructurii vizate pentru scanare.'
        />
        <Box
          title='Scanare IoT pe bază de agent'
          text='Este o metoda de identificare a configurărilor și extragerea informațiilor software asociate activelor depistate în etapa de descoperire ce presupune instalarea unui program permanent pe dispozitivele infrastructurii cibernetice.'
        />
        <Box
          title='Alerte, Rapoarte și Panouri de bord'
          text='Datele obținute în urma scanărilor efectuate sunt prelucrate de algoritmii SABLO în vederea furnizării de informații de securitate cibernetică utile pentru reducerea riscului de exploatare a infrastructurii cibernetice. Acestea sunt prezentate sub forma de alerte, rapoarte și vizualizări integrate în interfața grafica a platformei SABLO.'
        />
        <Box
          title='Configurări'
          text='Aceasta funcționalitate dezvoltata în cadrul SABLO permite personalizarea Panourilor de bord pe baza intrărilor oferite de utilizator.'
        />
        <Box
          title='Cloud Agnostic'
          text='Soluția SABLO permite descoperirea și scanarea infrastructurilor cibernetice care conțin componente exclusiv on-premise, on-premise și cloud-based sau exclusiv cloud-based.'
        />
      </div>
      <h2>Capturi de Ecran (Selecție)</h2>
      <div className='CapturesList'>
        <Capture image={Overview} description='Ecranul de tip „overview” cu expunerea la vulnerabilități cunoscute' />
        <Capture image={ScanSurface} description='Afișarea rețelelor scanate' />
        <Capture image={Vulns} description='Afișarea detaliilor unui element scanat' />
        <Capture image={Applications} description='Verificarea intrărilor (filtrare după textul „Ubuntu”)' />
        <Capture image={Alerts} description='Testarea filtrării după modul în ecranul de alerte' />
      </div>
    </div>
  )
}
