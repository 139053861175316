import * as React from 'react'

import Logo from './assets/logo.svg'

import './index.scss'

export default function Intro() {
  return (
    <div className='Intro'>
      <img src={Logo} alt='' />
      <div className='Title'>
        <h1>Platformă distribuită de securitate pentru IoT</h1>
        <h2>Cod MySmis: 105976</h2>
        <h2>Proiect subsidiar 1269/22.01.2018 de tip C</h2>
      </div>
    </div>
  )
}
