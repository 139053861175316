import * as React from 'react'

import './index.scss'

export interface ICaptureProps {
  image: string
  description?: string
}

export default function Capture(props: ICaptureProps) {
  return (
    <div className='Capture'>
      <img src={props.image} alt='' />
      {props.description && <p>{props.description}</p>}
    </div>
  )
}
