import * as React from 'react'

import './index.scss'

export default function Goals() {
  return (
      <div className='Goals'>
        <div className='Column'>
          <p>Proiectul SABLO a vizat dezvoltarea unei platforme distribuite de securitate pentru sistemele IoT, gestionând securitatea pe întreg ciclul de viață al acestora: de la proiectare și implementare, până la funcționarea lor în producție.</p>
          <p>Măsurabil, platforma SABLO permite analiza continuă și în timp real a riscurilor și vulnerabilităților platformelor IoT. Astfel, principalii vectori de atac vizați includ: interfețe web, cloud sau mobile nesigure, autentificare necorespunzătoare, servicii și protocoale de rețea nesigure, criptare neadecvată a comunicațiilor și datelor, configurații eronate și protocoale nesigure. Acestea sunt câteva exemple dintre cele mai relevante tipuri de expuneri pe care platforma le va analiza. Sistemul distribuit de securitate este format dintr-o consolă centrală și set de senzori specifice produselor IoT. SABLO acționează în modul de detecție a riscurilor și poate fi integrat cu sistemele de control al accesului în rețea, oferind posibilitatea implementării capabilităților de tipul Self Healing Network pentru infrastructurile IoT.</p>
          <p>Un obiectiv central al platformei a presupus dezvoltarea capacității de determinare a modelelor de atac și compromitere a securității în cazul sistemelor IoT. Platforma SABLO vizează asigurarea securității în zona de autentificare, control acces, descărcarea sigura a datelor și detectarea programelor malware.</p>
        </div>
        <div className='Column'>
          <p>Proiectul a presupus dezvoltarea unui produs ușor de utilizat, cu o interfață simplă și intuitivă, astfel încât identificarea vulnerabilităților și a situațiilor de compromitere a securității cibernetice să fie facilă tuturor utilizatorilor, fără a fi necesară expertiză tehnică specializată în zona de securitate. În targetul de potențiali clienți ai soluției SABLO sunt vizate companiile mici și mijlocii, cu o experiență limitată și fără personal dedicat în zona de securitate cibernetică.</p>
          <p>De asemenea, obiectivele principale ale proiectului au cuprins și diferențierea fata de abordarea soluțiilor deja existente, care se concentrau pe zona Enterprise, companii cu departamente specializate unde implementarea unei soluții de evaluare a vulnerabilității și securitate cibernetică implica schimbări la nivel de business și o complexitate mare în privința utilizării ți înțelegerii.</p>
          <p>Fiecare etapa îndeplinită din evoluția proiectului și evaluarea periodică a modului în care produsul SABLO răspunde funcționalităților propuse inițial confirmă faptul că obiectivele asumate încă de la începutul dezvoltării produsului SABLO au fost atinse în totalitate.</p>
        </div>
      </div>
  )
}
