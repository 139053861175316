import React from 'react'

import Header from './components/Header'
import Intro from './components/Intro'
import Menu from './components/Menu'
import Section from './components/Section'
import Vision from './components/Vision'
import Goals from './components/Goals'
import Architecture from './components/Architecture'
import Functionalities from './components/Functionalities'
import Results from './components/Results'
import Footer from './components/Footer'

import './App.scss'

export default function App() {
  const onItemClick = (id: string) => {
    const element = document.getElementById(id)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth'})
    }
  }

  return (
    <div className='App'>
      <Header />
      <Intro />
      <Menu onItemClick={onItemClick}/>
      <Section id='vision' title='Viziunea SABLO'>
        <Vision />
      </Section>
      <Section id='goals' title='Obiectivele Proiectului SABLO'>
        <Goals />
      </Section>
      <Section id='architecture' title='Arhitectura Software a Sablo'>
        <Architecture />
      </Section>
      <Section id='functionalities' title='Funcționalitățile Principale ale SABLO'>
        <Functionalities />
      </Section>
      <Section id='results' title='Rezultate Proiectului'>
        <Results />
      </Section>
      <Footer />
    </div>
  )
}
