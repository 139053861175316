import * as React from 'react'

import Box from '../Box'

import Console from './assets/console.svg'
import Database from './assets/database.svg'
import Networking from './assets/networking.svg'
import Centralized from './assets/centralized.svg'
import BackScheme from './assets/backScheme.png'
import FrontScheme from './assets/frontScheme.png'

import './index.scss'

export default function Architecture() {
  return (
    <div className='Architecture'>
      <h2>Arhitectura Backend</h2>
      <p>Arhitectura software a backend-ului proiectului SABLO este alcătuită din patru componente principale:</p>
      <div className='ArchitectureSection'>
        <div className='ArchitectureBoxList'>
          <Box
            image={Console}
            title='Consola centrală'
            text='Este formată din patru elemente centrale: API-ul pentru Frontend, modulul de procesare a modelelor de date, gestionarea task-urilor și task-urile ce efectuează diverse tipuri de scanări și analize.'
          />
          <Box
            image={Database}
            title='Baza de date'
            text='Este o baza de date relațională ce conține datele utilizatorilor obținute prin diverse metode de colectare.'
          />
          <Box
            image={Networking}
            title='Rețeaua de agenți IoT'
            text='Arhitectura include o componenta de agent, în vederea raportării mai exacte și mai corecte prin colectarea de date suplimentare despre elementele din infrastructura prin intermediul agenților.'
          />
          <Box
            image={Centralized}
            title='Centralizatorul de informații de securitate și vulnerabilități (Cortex)'
            text='Constituie un serviciu necesar funcționării corespunzătoare a consolei centrale, prin expunerea unui API cu ajutorul căruia se aduc multiple date despre vulnerabilități și date cu privire la acestea, necesare în raportarea asupra infrastructurii monitorizate.'
          />
        </div>
        <img src={BackScheme} alt='' />
      </div>

      <h2>Arhitectura Frontend</h2>
      <p>Aplicația Frontend a produsului SABLO are o serie de componente structurale de bază, unele dintre ele fiind impuse de tehnologiile de bază folosite. Responsabilitățile acestor componente includ comunicarea cu API-ul, administrarea fluxurilor de date și sincronizarea stării cu server-ul.</p>
      <p>Arhitectura are trei componente principale:</p>
      <div className='ArchitectureSection'>
        <div className='ArchitectureBoxList'>
          <Box
            title='Servicii Frontend SABLO'
            text='Aceste servicii au rolul de a administra comunicarea cu API-ul. Ele iau forma unor funcții asincrone scrise în TypeScript, alături de definiții de tipuri atât pentru parametrii funcțiilor cât și pentru răspunsurile primite de la server.'
          />
          <Box
            title='Context React'
            text='Conceptul de Context din React oferă o metodă globala prin care pot fi păstrate ușor informațiile globale care sunt necesare (cunoscute și ca state) și de asemenea oferă un mod prin care datele pot fi accesate direct, doar declarând componenta respectiva ca un consumator.'
          />
          <Box
            title='Router URL-uri'
            text='Reprezintă componenta care permite afișarea conținutului către utilizator prin generarea acestuia complet pe client, client care va prelua și atribuțiile de selectare a resurselor ce trebuie afișate în funcție de URL-ul curent, precum și actualizarea URL-ului la schimbarea datelor afișate.'
          />
        </div>
        <img src={FrontScheme} alt='' />
      </div>
    </div>
  )
}
