import * as React from 'react'

import Box from '../Box'

import './index.scss'

export default function Results() {
  return (
    <div className='Results'>
      <Box title='Back-up și Disaster Recovery' />
      <Box title='Performanţă și Scalare' />
      <Box title='Proprietate intelectuală' />
      <Box title='Legislație aplicabilă' />
      <Box title='Utilizabilitate, ergonomie și accesibilitate' />
      <Box title='Disponibilitate înaltă' />
      <Box title='Securitatea sistemului' />
    </div>
  )
}
