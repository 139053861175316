import * as React from 'react'

import './index.scss'

export default function Footer() {
  return (
    <div className='Footer'>
      <div className='Indicators'>
        <div className='Column'>
          <h1>Beneficiar</h1>
          <h2>CODA Intelligence</h2>
        </div>
        <div className='Column'>
          <h1>Valoare totală</h1>
          <h2>688.750 lei</h2>
        </div>
        <div className='Column'>
          <h1>Contribuție proprie</h1>
          <h2>137.750 lei</h2>
        </div>
        <div className='Column'>
          <h1>Valoare nerambursabilă</h1>
          <h2>551.000 lei</h2>
        </div>
        <div className='Column'>
          <h1>Perioada de implementare</h1>
          <h2>01.04.2018 - 29.02.2020</h2>
        </div>
      </div>
      <div className='Project'>
        <p>Proiect co-finanţat din Fondul European de Dezvoltare Regională prin Programul Operaţional Competitivitate 2014-2020</p>
        <p>Conţinutul acestui material nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene sau a Guvernului României</p>
      </div>
    </div>
  )
}
