import * as React from 'react'

import './index.scss'

export interface IMenuProps {
  onItemClick: (id: string) => void
}

export default function Menu(props: IMenuProps) {
  return (
    <div className='Menu'>
      <p onClick={() => props.onItemClick('vision')}>Viziune</p>
      <p onClick={() => props.onItemClick('goals')}>Obiective</p>
      <p onClick={() => props.onItemClick('architecture')}>Arhitectura Software</p>
      <p onClick={() => props.onItemClick('functionalities')}>Funcționalități</p>
      <p onClick={() => props.onItemClick('results')}>Rezultate</p>
    </div>
  )
}
