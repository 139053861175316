import * as React from 'react';

import './index.scss'

export interface IBoxProps {
  image?: string
  title: string
  text?: string
}

export default function Box(props: IBoxProps) {
  return (
    <div className='Box'>
      {props.image && <img src={props.image} alt='' />}
      <h3>{props.title}</h3>
      {props.text && <p>{props.text}</p>}
    </div>
  )
}
